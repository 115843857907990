import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Automation, AutomationStatus } from 'types/automation.types'

export const USE_FIND_AUTOMATIONS_QUERY_KEY = 'useFindAutomations'

const ctSDK = getCTSDK()

export const useFindAutomations = ({
    limit = 0,
    offset = 10,
    createdAtStartAt,
    inStatusIds = [],
}: {
    limit?: number
    offset?: number
    createdAtStartAt?: string
    inStatusIds?: AutomationStatus[]
}) => {
    const ws = useRef<WebSocket | null>(null)

    const [total, setTotal] = useState(0)
    const [automations, setAutomations] = useState<Automation[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        [
            USE_FIND_AUTOMATIONS_QUERY_KEY,
            limit,
            offset,
            inStatusIds,
            createdAtStartAt,
        ],
        async () =>
            await ctSDK.get<{ automations: Automation[]; total: number }>(
                '/automations',
                {
                    params: {
                        limit,
                        offset,
                        createdAtStartAt,
                        inStatusIds: inStatusIds.join(','),
                    },
                }
            ),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setAutomations(data?.data?.automations || [])
            },
        }
    )

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)
            if (
                ['automationCreated', 'automationUpdated'].includes(
                    wsEvent.type
                )
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        total,
        rows: automations,
        error,
        refetch,
    }
}
