import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Order } from 'types/order.types'
import { getCTSDK } from 'services/ctClient'

export const USE_FIND_ORDERS_QUERY_KEY = 'useFindOrders'

const ctSDK = getCTSDK()

export const useFindOrders = ({
    limit = 50,
    offset = 0,
}: {
    limit?: number
    offset?: number
}) => {
    const ws = useRef<WebSocket | null>(null)

    const [total, setTotal] = useState(0)
    const [orders, setOrders] = useState<Order[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        [USE_FIND_ORDERS_QUERY_KEY, limit, offset],
        async () =>
            await ctSDK.get<{ orders: Order[]; total: number }>('/orders', {
                params: {
                    limit,
                    offset,
                },
            }),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setOrders(data?.data?.orders || [])
            },
        }
    )

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (['orderUpdated'].includes(wsEvent.type)) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        total,
        rows: orders,
        error,
        refetch,
    }
}
