import { useEffect, useRef } from 'react'
import { useGetQueuedPayables } from 'queries/payable.queries'

export const useQueuedPayables = () => {
    const ws = useRef<WebSocket | null>(null)

    const { isLoading, isRefetching, error, data, refetch } =
        useGetQueuedPayables()

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (['payableQueued', 'payableProcessed'].includes(wsEvent.type)) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        error,
        payables: data?.data?.payables || [],
        refetch,
    }
}
