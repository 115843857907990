import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { OrderDetails } from './components/OrderDetails'
import {
    useGetOrderById,
    useGetOrderCommoditiesById,
} from 'shipperPortal/queries/order.queries'
import { showToastSuccess } from 'hooks/useToast'
import { URLS } from 'utils/url.utils'
import { getPublicUrl } from 'utils/env.utils'

export const OrderDetailsContainer = () => {
    const ws = useRef<WebSocket | null>(null)
    const { id } = useParams()
    const {
        isLoading: isLoadingOrder,
        data: orderData,
        refetch: refetchOrder,
    } = useGetOrderById(id)
    const {
        isLoading: isLoadingCommodities,
        data: commoditiesData,
        refetch: refetchCommmodities,
    } = useGetOrderCommoditiesById(id)

    const isLoading = isLoadingOrder || isLoadingCommodities

    const order = orderData?.data?.order
    const commodities = commoditiesData?.data?.commodities || []

    const _onRefetch = () => {
        refetchOrder()
        refetchCommmodities()
    }

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (['orderUpdated'].includes(wsEvent.type)) {
                _onRefetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _onGenerateTrackingLink = () => {
        const trackingToken = order?.trackingToken

        if (!trackingToken) {
            return
        }

        const trackingLink = getPublicUrl(URLS.cartage.tracking(trackingToken))

        navigator.clipboard.writeText(trackingLink)

        showToastSuccess('Link copied to clipboard')
    }

    return (
        <OrderDetails
            isLoading={isLoading}
            order={order}
            commodities={commodities}
            onGenerateTrackingLink={_onGenerateTrackingLink}
        />
    )
}
