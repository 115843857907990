import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Document, DocumentStatus } from 'types/document.types'

const ctSDK = getCTSDK()

export const useFindDocuments = ({
    limit = 0,
    offset = 10,
    inStatusIds = [],
}: {
    limit?: number
    offset?: number
    inStatusIds?: DocumentStatus[]
}) => {
    const ws = useRef<WebSocket | null>(null)

    const [total, setTotal] = useState(0)
    const [documents, setDocuments] = useState<Document[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        ['useFindDocuments', limit, offset, inStatusIds],
        async () =>
            await ctSDK.get<{ documents: Document[]; total: number }>(
                '/documents',
                {
                    params: {
                        limit,
                        offset,
                        inStatusIds: inStatusIds.join(','),
                    },
                }
            ),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setDocuments(data?.data?.documents || [])
            },
        }
    )

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (['documentCreated', 'documentParsed'].includes(wsEvent.type)) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        total,
        documents,
        error,
        refetchDocuments: refetch,
    }
}
