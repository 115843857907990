import { useEffect, useRef, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
    useGetOrderById,
    useGetOrderCommoditiesById,
} from 'queries/order.queries'
import { OrderDetails } from './components/OrderDetails'
import {
    useGenerateRateConfirmationPdf,
    useUpdateCommodities,
    useUpdateOrder,
} from 'mutations/order.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { DestinationType, OrderChangeProp } from './orderDetails.types'
import { Order } from 'types/order.types'
import {
    checkIfOrderHasChanged,
    compareCommodities,
    getMissingRateConProperties,
} from './utils/orderDetails.utils'
import { OrderCommodity } from 'types/orderCommodity.types'
import {
    createNewEmptyCommodity,
    deleteAllNewCommodityIds,
} from './utils/orderDetails.utils'
import { URLS } from 'utils/url.utils'
import { getPublicUrl } from 'utils/env.utils'
import { downloadFile } from 'utils/downloadBlob.utils'

const OrderDetailsContainer = () => {
    const ws = useRef<WebSocket | null>(null)
    const { id } = useParams()
    const [isCarrierDialogOpen, setIsCarrierDialogOpen] = useState(false)
    const {
        isLoading: isLoadingOrder,
        data: orderData,
        refetch: refetchOrder,
    } = useGetOrderById(id)
    const {
        isLoading: isLoadingCommodities,
        data: commoditiesData,
        refetch: refetchCommmodities,
    } = useGetOrderCommoditiesById(id)
    const { isLoading: isUpdatingOrder, mutateAsync: updateOrder } =
        useUpdateOrder()
    const { isLoading: isUpdatingCommodities, mutateAsync: updateCommodities } =
        useUpdateCommodities()
    const { mutateAsync: getPdfRateConBlob } = useGenerateRateConfirmationPdf()

    const isLoading = isLoadingOrder || isLoadingCommodities

    const isSubmitting = isUpdatingOrder || isUpdatingCommodities

    const order = orderData?.data?.order
    const commodities = commoditiesData?.data?.commodities || []

    const [tempOrder, setTempOrder] = useState<Order | undefined>(order)
    const [tempCommodities, setTempCommodities] = useState<
        Partial<OrderCommodity>[]
    >([...commodities, createNewEmptyCommodity()])

    useEffect(() => {
        if (commodities) {
            setTempCommodities([...commodities, createNewEmptyCommodity()])
        }
    }, [commodities])

    useEffect(() => {
        setTempOrder(order)
    }, [order])

    const _onRefetch = () => {
        refetchOrder()
        refetchCommmodities()
    }

    const _onMarkPickedUp = async () => {
        if (!tempOrder) {
            return
        }

        try {
            await updateOrder({
                id: tempOrder.id,
                order: { pickedUpAt: new Date().toISOString() },
            })
            _onRefetch()

            showToastSuccess('Order marked as picked up')
        } catch (error) {
            showToastError('Error marking order as picked up')
        }
    }

    const _onMarkDelivered = async () => {
        if (!tempOrder) {
            return
        }

        try {
            await updateOrder({
                id: tempOrder.id,
                order: { deliveredAt: new Date().toISOString() },
            })
            _onRefetch()
            showToastSuccess('Order marked as delivered')
        } catch (error) {
            showToastError('Error marking order as delivered')
        }
    }

    const _onChangeOrder = (orderMutation: Partial<Order>) => {
        if (!tempOrder) {
            return
        }

        setTempOrder({ ...tempOrder, ...orderMutation })
    }

    const _onCloseOrderEditPopup = async (args: {
        propName: OrderChangeProp
        index?: number
        destinationType?: DestinationType
    }) => {
        const newOrder = { ...tempOrder }

        if (!tempOrder || !order) {
            return
        }

        if (
            !checkIfOrderHasChanged({
                ...args,
                order,
                newOrder: tempOrder,
            })
        ) {
            return
        }

        await updateOrder({
            id: order.id,
            order: newOrder,
        })
    }

    const _onChangeCommodities = (commodities: Partial<OrderCommodity>[]) => {
        const newTempCommodities = [...commodities]

        if (newTempCommodities.every((commodity) => !!commodity.description)) {
            newTempCommodities.push(createNewEmptyCommodity())
        }

        setTempCommodities(newTempCommodities)
    }

    const _onCloseCommoditiesEditPopup = async () => {
        if (!tempOrder?.id) {
            return
        }

        // get only commodities that could be saved
        const newCommodities = tempCommodities.filter(
            (commodity) =>
                !!commodity.description &&
                !!commodity.mode &&
                !!commodity.quantity &&
                !!commodity.weight
        )

        if (compareCommodities(commodities, newCommodities)) {
            return
        }

        const updatedCommodities = await updateCommodities({
            orderId: tempOrder?.id,
            // @ts-ignore
            commodities: deleteAllNewCommodityIds(newCommodities),
        })

        setTempCommodities([...updatedCommodities, createNewEmptyCommodity()])
    }

    const _onGenerateTrackingLink = () => {
        const trackingToken = order?.trackingToken

        if (!trackingToken) {
            return
        }

        const trackingLink = getPublicUrl(URLS.cartage.tracking(trackingToken))

        navigator.clipboard.writeText(trackingLink)

        showToastSuccess('Link copied to clipboard')
    }

    const _onAddDestination = async (type: DestinationType) => {
        if (!order) {
            return
        }

        const destinations =
            type === 'pickup' ? order.extraPickups : order.extraDropoffs

        const newExtraDestinations = [...(destinations || []), {}]

        const orderMutation =
            type === 'pickup'
                ? { extraPickups: newExtraDestinations }
                : { extraDropoffs: newExtraDestinations }

        await updateOrder({
            id: order.id,
            order: { ...order, ...orderMutation },
        })
    }

    const _onDeleteDestination = async (
        type: DestinationType,
        index: number
    ) => {
        if (!order) {
            return
        }

        const destinations =
            type === 'pickup' ? order.extraPickups : order.extraDropoffs

        const newExtraDestinations = destinations?.filter((_, i) => i !== index)

        const orderMutation =
            type === 'pickup'
                ? { extraPickups: newExtraDestinations }
                : { extraDropoffs: newExtraDestinations }

        await updateOrder({
            id: order.id,
            order: { ...order, ...orderMutation },
        })
    }

    const _onGenerateRateCon = useCallback(async () => {
        if (!tempOrder?.id || !tempOrder?.fullId) {
            return
        }

        const missingValues = getMissingRateConProperties(tempOrder)

        if (missingValues) {
            showToastError(
                `Missing the following properties to generate Rate Confirmation: ${missingValues.join(
                    ', '
                )}`
            )
            return
        }

        const blob = await getPdfRateConBlob(tempOrder?.id)

        downloadFile(blob, `Rate confirmation ${tempOrder.fullId}.pdf`)
    }, [getPdfRateConBlob, tempOrder])

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (['orderUpdated'].includes(wsEvent.type)) {
                _onRefetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <OrderDetails
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            isCarrierDialogOpen={isCarrierDialogOpen}
            order={order}
            orderMutation={tempOrder}
            commoditiesMutations={tempCommodities}
            commodities={commodities}
            onMarkPickedUp={_onMarkPickedUp}
            onMarkDelivered={_onMarkDelivered}
            onChangeOrder={_onChangeOrder}
            onCloseOrderEditPopup={_onCloseOrderEditPopup}
            onChangeCommodities={_onChangeCommodities}
            onCloseCommoditiesEditPopup={_onCloseCommoditiesEditPopup}
            onChangeIsCarrierDialogOpen={setIsCarrierDialogOpen}
            onGenerateTrackingLink={_onGenerateTrackingLink}
            onAddDestination={_onAddDestination}
            onDeleteDestination={_onDeleteDestination}
            onGenerateRateCon={_onGenerateRateCon}
        />
    )
}

export default OrderDetailsContainer
